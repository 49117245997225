exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-beliefs-js": () => import("./../../../src/pages/beliefs.js" /* webpackChunkName: "component---src-pages-beliefs-js" */),
  "component---src-pages-bibleandprayer-js": () => import("./../../../src/pages/bibleandprayer.js" /* webpackChunkName: "component---src-pages-bibleandprayer-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diary-js": () => import("./../../../src/pages/diary.js" /* webpackChunkName: "component---src-pages-diary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-meetings-js": () => import("./../../../src/pages/meetings.js" /* webpackChunkName: "component---src-pages-meetings-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-safeguarding-js": () => import("./../../../src/pages/safeguarding.js" /* webpackChunkName: "component---src-pages-safeguarding-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-sermons-js": () => import("./../../../src/pages/sermons.js" /* webpackChunkName: "component---src-pages-sermons-js" */),
  "component---src-pages-youngpeople-js": () => import("./../../../src/pages/youngpeople.js" /* webpackChunkName: "component---src-pages-youngpeople-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

