import React from 'react';
import useMenuChange from './handler';

const styles = [
    "[&>div:nth-child(1)]:rotate-45 [&>div:nth-child(1)]:translate-y-[7.5px] [&>div:nth-child(1)]:sm:translate-y-[11px] [&>div:nth-child(3)]:-rotate-45 [&>div:nth-child(3)]:-translate-y-[7.5px] [&>div:nth-child(3)]:sm:-translate-y-[11px] [&>div:nth-child(even)]:opacity-0",
    "" 
];
let currentStyle = styles[1];


const Burger = ()  => {

    const [ open, change ] = useMenuChange()

    if (open) {
        currentStyle = styles[0]
    }
    else {
        currentStyle = styles[1]
    }
    //console.log ("currentStyle: ", {currentStyle})

  return (
      <div className={'lg:h-0 grid items-center grid-cols-1 gap-[5px] sm:gap-[8px] w-6 h-[18px] sm:w-8 sm:h-[28px] py-[1.0px] sm:py-[1.5px] [&>div]:w-full [&>div]:h-[2.5px] [&>div]:sm:h-[3px] [&>div]:bg-neutral-100 [&>div]:rounded' + ' ' + currentStyle} onClick={change}>
        <div className='transition duration-300 ease-in-out'/>
        <div className='transition duration-300 ease-in-out'/>
        <div className='transition duration-300 ease-in-out'/>
      </div>
  )

}
export default Burger