import React from 'react'
import Navigation from './navigation'
import { motion, AnimatePresence } from 'framer-motion'

const durationExitMain = 0
const durationMain = 1.2
const delayMain = 1.0
const delayExitMain = 0.5

const variantsMain = {
  initial: {
    opacity: 0,
    x: 0,

  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: durationMain,
      delay: delayMain,
      when: "beforeChildren",
      ease: "easeInOut"
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: { 
      duration: durationExitMain,
      delay: delayExitMain,
      ease: "easeInOut"
    },
  },
}

const isBrowser = typeof window !== "undefined";

const Layout = ({ children }) => {
  return (
    <div className='h-full'>
      <Navigation />
        {children}
    </div>
  )
}

export default Layout