import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import Burger from "./burger-menu/burger";
import BurgerMenu from "./burger-menu/menu";
import Logo from "./logo";

function compareMenuItems( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

const Navigation = () => {
    const data = useStaticQuery(graphql`
        query NavigationQuery{
            allMarkdownRemark {
                edges {
                    node {                
                        frontmatter {
                            title
                            url
                            order
                        }
                    }
                }
            }
        }
    `)

    const nodes = Object.entries(data.allMarkdownRemark.edges)
    const menu = new Array()

    for (const [key, value] of nodes) {
        const entry = new Object
        const frontmatter = value?.node?.frontmatter
        entry.title = frontmatter?.title
        entry.url = frontmatter?.url
        entry.order = frontmatter?.order
        if (entry.order !== "0") {
            menu.push(entry)
        }
    }

    menu.sort(compareMenuItems)

    //console.log("Menu:", menu)
  
    const items = new Array()

    menu.forEach(item => {
        if (item.url.match(/^\s?http(s?)/gi)) {
            items.push(
                    <li key={item.order} className="text-base" role="menuitem">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.title}
                        </a>
                    </li>
                )
            } else {
            items.push(
                    <li key={item.order} className="text-base" role="menuitem">
                        <div>
                            <Link activeClassName="text-white" className='block w-full text-neutral-200 hover:text-white focus:text-white text-2xl sm:text-4xl lg:text-2xl font-lato font-light ease-in-out duration-300' to={item.url} >
                                {item.title}
                            </Link>
                        </div>
                    </li>
                )
            }
        }
    )    

    
    return (
        <>
            <div className="fixed top-0 w-full flex items-center h-10 sm:h-20 bg-dark-green py-2 text-white z-20">
                <div className="xl:container w-full grid grid-cols-2 mx-auto items-center px-4 md:px-4 2xl:px-32">
                    <Link to="/">
                        <div className="grid grid-flow-col auto-cols-max gap-6 justify-start font-lato">
                            <Logo/>
                            <div className="hidden xl:grid w-full items-end">
                                <h1 className="text-2xl font-lato font-light">Elswick Parish Church</h1>
                                <h3 className="text-xs font-lato font-light">Evangelical Church in Elswick, Newcastle upon Tyne</h3>
                            </div>
                        </div>
                    </Link>
                    <div className='grid lg:hidden align-middle justify-self-end justify-items-end'>
                        <Burger/>
                    </div>
                    <div className="hidden lg:inline-block basis-1/2 align-middle">
                        <ul className="flex flex-row gap-8 justify-end">{items}</ul>
                    </div>
                </div>
            </div>
            <BurgerMenu items={items}/>
        </>
    )
}




  
export default Navigation