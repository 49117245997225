import React from 'react';
import useMenuChange from './handler';


const styles = [
    "transition duration-300 ease-in-out",
    "translate-x-full transition delay-200 duration-500 ease-in-out" 
];
let currentStyle = styles[1];


const BurgerMenu = ({ items }) => {

    const [ open, change ] = useMenuChange()

    if (open) {
        currentStyle = styles[0]
    }
    else {
        currentStyle = styles[1]
    }
    //console.log ("currentStyle: ", {currentStyle})

    const newItems = new Array()

    items.forEach(listItem => {
        const newListItem = React.cloneElement(listItem, { onClick: change })
        newItems.push(newListItem)        
    });

  return (
    <div className={'fixed right-0 top-10 sm:top-20 h-screen overflow-y-auto scrollbar-hide bg-dark-green w-40 sm:w-52 lg:hidden z-10' + ' ' + currentStyle}>
        <div className='mx-auto px-4 xl:px-20'>
            <ul className='grid grid-cols-1 h-fit gap-8 pt-4 pb-4 mb-10 sm:mb-20 text-right'>
                {newItems}
            </ul>
        </div>
    </div>
  )
}


export default BurgerMenu